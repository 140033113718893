<template>
  <header class="header sticky-header">
    <div class="content-wrapper">
      <div class="content">
        <v-img
            :src="require('images/logo.svg')" alt="Logo" class="logo" height="50" width="250"
            @click="goToPage('home')"
        />
      </div>

      <div v-show="showMenu" class="menu-wrapper">
        <Menu :toggle-mobile-menu="toggleMobileMenu"></Menu>

        <v-divider class="menu-divider"></v-divider>

        <div v-if="isLoggedIn && isMobile" class="my-account-container">
          <button
              :class="{ active: route.path === '/panel-uzytkownika' }"
              class="menu-link"
              @click="goToPage('dashboard')"
          >Panel użytkownika
          </button>
          <button
              :class="{ active: route.path === '/aktywacja-profilu' }"
              class="menu-link"
              @click="goToPage('profile-activation')"
          >Aktywuj profil</button>
          <button
              :class="{ active: route.path === '/zmiana-hasla' }"
              class="menu-link"
              @click="goToPage('change-password')"
          >Zmień hasło
          </button>
          <button class="menu-link" @click="logout">Wyloguj się</button>
        </div>

        <div v-else-if="isLoggedIn" class="my-account-container">
          <div class="my-account-wrapper">
            <div class="buttons-wrapper">
              <button class="activation-button outlined-button" @click="goToPage('profile-activation')">Aktywuj profil</button>

              <div class="button-wrapper">
                <v-btn prepend-icon="mdi-account" class="my-account-button">Moje konto</v-btn>
              </div>
            </div>
            <div class="dropdown-menu">
              <v-btn @click="goToPage('dashboard')">Panel użytkownika</v-btn>
              <v-btn @click="goToPage('change-password')">Zmień hasło</v-btn>
              <v-btn @click="logout">Wyloguj się</v-btn>
            </div>
          </div>
        </div>
        <div v-else class="auth-buttons-group">
          <button class="activation-button outlined-button" @click="goToPage('profile-activation')">Aktywuj profil</button>
          <button class="black-button login-button" @click="goToPage('login')">Zaloguj się</button>
        </div>
      </div>

      <button v-if="isMobile" :class="{'hamburger': true, 'open': mobileMenuActive}" @click="toggleMobileMenu">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </header>
</template>

<script>
import {computed, ref, watchEffect} from "vue";
import {useRoute} from "vue-router";
import {useMediaQuery} from '@vueuse/core';
import {useAuthStore} from "@/stores/auth";
import Menu from "@/components/Layout/Menu.vue";

export default {
  components: {Menu},
  setup() {
    const isLoggedIn = ref(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const mobileMenuActive = ref(false);
    const authStore = useAuthStore();

    watchEffect(() => {
      isLoggedIn.value = authStore.isLoggedIn;
    });

    const toggleMobileMenu = () => {
      mobileMenuActive.value = !mobileMenuActive.value;
    };

    const closeMobileMenu = () => {
      mobileMenuActive.value = false;
    };

    const showMenu = computed(() => {
      return mobileMenuActive.value || !isMobile.value;
    });

    const route = useRoute();

    return {
      isLoggedIn,
      isMobile,
      mobileMenuActive,
      toggleMobileMenu,
      closeMobileMenu,
      showMenu,
      route,
      authStore,
    };
  },
  methods: {
    logout() {
      this.authStore.logout();
      this.closeMobileMenu();
    },
    goToPage(page) {
      this.closeMobileMenu();
      this.$router.push({name: page});
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  height: 64px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (min-width: 768px) {
    padding: 10px 20px 0;
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    cursor: pointer;

    &-box {
      position: relative;
      width: 24px;
      height: 18px;
    }

    &-inner {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #1C1B1B;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #1C1B1B;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
      }

      &::before {
        top: -6px;
      }

      &::after {
        bottom: -6px;
      }
    }

    &.open {
      .hamburger-inner {
        background-color: transparent;

        &::before {
          transform: translateY(6px) rotate(45deg);
        }

        &::after {
          transform: translateY(-6px) rotate(-45deg);
        }
      }
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: 1200px;
  }

  .content {
    justify-content: flex-start;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
  }
}

.logo {
  width: 100%;
  max-width: 100px;
  cursor: pointer;

  @media (min-width: 768px) {
    max-width: 250px;
    height: 60px !important;
  }
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px 32px;
  background-color: #ffffff;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
  height: calc(100vh - 64px);

  @media (min-width: 768px) {
    position: relative;
    flex-direction: row;
    align-items: flex-end;
    top: -3px;
    left: unset;
    right: unset;
    height: 100%;
    gap: 32px;
    padding: 0;
    box-shadow: none;
    overflow: unset;
  }
}

.menu-divider {
  margin: 8px 0;
}

.menu-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .activation-button {
    margin-bottom: 0;
  }
}

.button-wrapper {
  height: 100%;
  position: relative;
}

.my-account-button {
  height: 32px;
  text-transform: capitalize;
}

.my-account-container {
  height: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 0;

  @media (min-width: 768px) {
    padding-bottom: 8px;
    justify-content: flex-end;
  }

  &:hover {
    .dropdown-menu {
      display: flex;
      opacity: 1;
    }
  }
}

.auth-buttons-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 2rem;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    margin-top: 0;
    justify-content: flex-start;
  }
}

.login-button {
  height: 40px;
  width: 100%;
  margin: 0;
  padding: 0 16px;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: 0.2px;

  @media (min-width: 480px) {
    width: 50%;
  }

  @media (min-width: 768px) {
    height: 32px;
    width: auto;
    font-size: 0.875rem;
    margin-bottom: 8px;
  }

  &:hover {
    background-color: #A28546;
  }
}

.horizontal-separator {
  margin: 0 24px 0 0;
  border: 0;
  border-top: 1px solid #000000;
  opacity: 1;
}

.dropdown-menu {
  display: none;
  position: absolute;
  flex-direction: column;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
  top: 100%;
  right: 0;
  opacity: 0;
  border-top: 3px solid #000000;
  transition: opacity 0.3s ease;

  button {
    width: 100%;
    text-align: left;
    padding: 10px 20px; /* Increase padding */
    font-size: 16px; /* Increase font size */
    text-transform: unset;
  }
}

.menu-link {
  padding: 12px 32px 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 1.125rem;
  border-right: 4px solid transparent;
  color: #1C1B1B;
  white-space: nowrap;

  @media (min-width: 768px) {
    padding: 12px 16px 8px;
    border-radius: 10px 10px 0 0;
    color: #000000;
  }

  &:hover {
    background-color: transparent;
    border-color: #c0c0c0;
    color: #000000;

    @media (min-width: 768px) {
      background-color: #c0c0c0;
      border-color: transparent;
    }
  }

  &.active {
    border-color: #000000;
    color: #000000;

    @media (min-width: 768px) {
      background-color: #000000;
      border-color: transparent;
      color: #ffffff;
      cursor: default;
    }
  }
}
</style>
